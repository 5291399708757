import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import CollectionPageComponent from "../../components/product/collection-page-components"

const CollectionTemplate = ({ data, location }) => {
  const { collection_title } = data.sanityCollection
  return (
    <Layout>
      <SEO title={collection_title} url={location.pathname} />
      <CollectionPageComponent data={data} />
    </Layout>
  )
}

export default CollectionTemplate

export const query = graphql`
  query($slug: String!) {
    sanityCollection(slug: { current: { eq: $slug } }) {
      collection_title
      collection_intro_title
      collection_intro_text
      featured_recipe {
        slug {
          current
        }
        recipe_title
        recipe_image_image {
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          video
        }
      }
    }
    allSanityProduct(
      filter: { product_collection: { slug: { current: { eq: $slug } } } }
    ) {
      nodes {
        _id
        product_name
        slug {
          current
        }
        _type
        product_flavor_profile {
          score {
            title
            score
          }
        }
        product_variants {
          variant_type
          variants {
            _key
            variant_info_price
            variant_info_value
          }
        }
        product_collection {
          slug {
            current
          }
        }
        product_label {
          label_title
          label_color {
            section_background
          }
        }
        product_disabled
        product_image {
          asset {
            fluid(maxWidth: 500) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
